import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './RegisterFreeAccount.scss';

function RegisterFreeAccount() {
  return (
    <Form className="form-body">
      <h2>Start using AcmeCRM today!</h2>
      <Row className="form-field">
        <Form.Group as={Col} controlId="formGridFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control placeholder="Enter first name" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control placeholder="Enter your last name" />
        </Form.Group>
      </Row>
      <Form.Group className="company-info" controlId="formGridCompany">
        <Form.Label>Company Name</Form.Label>
        <Form.Control placeholder="Enter your company name" />
      </Form.Group>
  
      <Form.Group className="business-info" controlId="formGridEmail">
        <Form.Label>Business Email</Form.Label>
        <Form.Control placeholder="Enter your business email" />
      </Form.Group>
    
      <Row>
        <Form.Group className="country-select" as={Col} controlId="formGridCountry">
          <Form.Label>Country</Form.Label>
          <Form.Select defaultValue="Choose Country...">
            <option>Choose...</option>
            <option>Australia</option>
            <option>Canada</option>
            <option>France</option>
            <option>Germany</option>
            <option>Japan</option>
            <option>Mexico</option>
            <option>Spain</option>
            <option>United Kingdom</option>
            <option>United States of America</option>
          </Form.Select>
        </Form.Group>
      </Row>
        <p></p>
        <p></p>
        <p></p>
      <Form.Group className="form-field" id="formGridCheckbox">
        <Form.Check type="checkbox" label="I agree to the Services Agreement and Privacy Policy" />
      </Form.Group>
      
      <div className="d-grid gap-2">
      <Button variant="primary" size="lg" type="submit" href="https://crm.pendoexperience.io">
        Create Free Account
      </Button>
      </div>
    </Form>
  );
}

export default RegisterFreeAccount;