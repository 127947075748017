import '../../App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ResourceTile from '../ResourceTile';

export default function LandingPage(){
    return (
        <Container>
            <Row>
            <Col>
                <h2 className="section-header-text">Pendo for Customers</h2>
            </Col>
            </Row>
            <Row>
            <ResourceTile
                title="Pendo"
                imgSrc="./images/pendo-chevron-pink.svg"
                imgStyle={{ maxHeight: '150px', height: '80px', width: '150px' }}
                url="https://app.pendo.io/s/6591622502678528/dashboards"
            ></ResourceTile>
            <ResourceTile
                title="Acme CRM - Lightning"
                imgSrc="./images/acmecrm-new.svg"
                imgStyle={{}}
                url="https://crm.pendoexperience.io"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2649554979/Engage+Demo+Acme+CRM"
            ></ResourceTile>
            {/* <ResourceTile
                title="Engage Microdemos"
                imgSrc="./images/Pendo_Chevron_Pink.png"
                imgStyle={{ width: '40%' }}
                url="https://pendomicrodemos.io/engage/home"
            ></ResourceTile> */}
            </Row>
            <Row>
            <Col>
                <h2 className="section-header-text">Pendo for Employees</h2>
            </Col>
            </Row>
            <Row>
            <ResourceTile
                title="Pendo"
                imgSrc="./images/pendo-chevron-pink.svg"
                imgStyle={{ maxHeight: '150px', height: '80px', width: '150px' }}
                url="https://app.pendo.io/s/5685572921851904/dashboards/fP4gSy-t0SrbZRtVltiX1B6Augs"
            ></ResourceTile>
            <ResourceTile
                title="Salesforce"
                imgSrc="./images/salesforce-logo.svg"
                imgStyle={{}}
                url="https://pendo--se.sandbox.lightning.force.com"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2418049082"
            ></ResourceTile>
            <ResourceTile
                title="Salesforce Financial Services Cloud"
                imgSrc="./images/salesforce-finserv-logo.png"
                imgStyle={{ width: '40%'}}
                url="https://pendo4.lightning.force.com/lightning/page/home"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/3975119496/"
            ></ResourceTile>
            {/* <ResourceTile
                title="ServiceNow Service Portal"
                imgSrc="./images/servicenow-logo.svg"
                imgStyle={{}}
                url="https://ven04592.service-now.com/sp"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2437677139/Adopt+Demo+ServiceNow+Service+Portal"
            ></ResourceTile> */}
            <ResourceTile
                title="ServiceNow Employee Center"
                imgSrc="./images/servicenow-logo.svg"
                imgStyle={{}}
                url="https://ven04592.service-now.com/esc"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2906652957/Adopt+Demo+ServiceNow+Employee+Center"
            ></ResourceTile>
            <ResourceTile
                title="Workday"
                imgSrc="./images/workday-logo.svg"
                imgStyle={{}}
                url="https://impl.workday.com/wday/authgwy/pendo_gms2/login.htmld"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2439348225"
            ></ResourceTile>

            <ResourceTile
                title="Microsoft 365"
                imgSrc="./images/microsoft-365-logo.png"
                imgStyle={{}}
                url="https://pendodemo.crm.dynamics.com/"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2438987781"
            ></ResourceTile>
            <ResourceTile
                title="Atlassian (Jira, Confluence)"
                imgSrc="./images/JiraConfLogo.png"
                imgStyle={{}}
                url="https://pendodemo.atlassian.net"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2729934918/Adopt+Demo+Atlassian"
            ></ResourceTile>
            <ResourceTile
                title="GitHub"
                imgSrc="./images/GitHub-Logo.png"
                imgStyle={{}}
                url="https://github.com/pendo-se-sandbox"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2739896395/Adopt+Demo+GitHub"
            ></ResourceTile>
            <ResourceTile
                title="Coupa"
                imgSrc="./images/coupa-logo.png"
                imgStyle={{}}
                url="https://pendo-usa-coupalink-demo.coupacloud.com/session/new"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/2786787363/Adopt+Demo+Coupa"
            ></ResourceTile>
            </Row>
            <Row>
            <Col>
                <h2 className="section-header-text">
                Verticalized Demo Environments
                </h2>
            </Col>
            </Row>
            <Row>
            <ResourceTile
                title="AcmeInvestments"
                imgSrc="./images/investments.svg"
                imgStyle={{
                width: '40%',
                }}
                url="https://investments.pendoexperience.io"
                docUrl="https://docs.google.com/spreadsheets/d/1GRSEi1_YWxxcIDRx8Hajbr6fyeErVAHVo1370yYMFl4/edit#gid=2009084751"
            ></ResourceTile>
            <ResourceTile
                title="AcmeInsurance"
                imgSrc="./images/insurance.png"
                imgStyle={{
                width: '40%',
                }}
                url="https://insurance.pendoexperience.io"
                docUrl="https://docs.google.com/spreadsheets/d/1GRSEi1_YWxxcIDRx8Hajbr6fyeErVAHVo1370yYMFl4/edit#gid=1082073200"
            ></ResourceTile>
            <ResourceTile
                title="AcmeEHR"
                imgSrc="./images/ehr.png"
                imgStyle={{
                width: '40%',
                }}
                url="https://ehr.pendoexperience.io"
                docUrl="https://docs.google.com/spreadsheets/d/1GRSEi1_YWxxcIDRx8Hajbr6fyeErVAHVo1370yYMFl4/edit#gid=1082073200"
            ></ResourceTile>
            <ResourceTile
                title="AcmeHR"
                imgSrc="./images/hr-logo.png"
                imgStyle={{
                width: '40%',
                }}
                url="https://hr.pendoexperience.io"
                docUrl="https://docs.google.com/spreadsheets/d/1GRSEi1_YWxxcIDRx8Hajbr6fyeErVAHVo1370yYMFl4/edit#gid=1082073200"
            ></ResourceTile>
            </Row>
            <Row>
                <Col>
                <h2 className="section-header-text">Acme Marketing</h2>
                </Col>
            </Row>
            <Row>
            <ResourceTile
                title="Acme CRM Request a Demo"
                imgSrc="./images/acmecrm-new.svg"
                imgStyle={{ maxHeight: '150px', height: '80px', width: '150px' }}
                url="/schedule-demo"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/3704782882/AcmeCRM+Identity+Management+Anonymous+to+Known"
            ></ResourceTile>
            <ResourceTile
                title="Acme CRM Register for Free"
                imgSrc="./images/acmecrm-new.svg"
                imgStyle={{ maxHeight: '150px', height: '80px', width: '150px' }}
                url="/free-account"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/3704782882/AcmeCRM+Identity+Management+Anonymous+to+Known"
            ></ResourceTile>
            </Row>
            <Row>
            <Col>
                <h2 className="section-header-text">Micro Sites</h2>
            </Col>
            </Row>
            <Row>
            <ResourceTile
                title="AcmeA11Y"
                imgSrc="./images/a11y.png"
                imgStyle={{
                width: '40%',
                }}
                url="https://a11y.pendoexperience.io"
                docUrl="https://docs.google.com/spreadsheets/d/1GRSEi1_YWxxcIDRx8Hajbr6fyeErVAHVo1370yYMFl4/edit#gid=2677474"
            ></ResourceTile>
            <ResourceTile
                title="Shadow DOM"
                imgSrc="./images/shadow-dom-logo.svg"
                imgStyle={{}}
                url="https://pe-shadow-dom-dot-pendo-demo-eng.ue.r.appspot.com/"
            ></ResourceTile>
            <ResourceTile
                title="React + Tailwind CSS"
                imgSrc="./images/tailwind-logo.svg"
                imgStyle={{}}
                url="https://pe-tailwind-react-dot-pendo-demo-eng.ue.r.appspot.com/"
            ></ResourceTile>
            <ResourceTile
                title="React + Material UI"
                imgSrc="./images/material-ui-logo.svg"
                imgStyle={{}}
                url="https://pe-material-ui-dot-pendo-demo-eng.ue.r.appspot.com/admin/index"
            ></ResourceTile>
            <ResourceTile
                title="acmeCNAME"
                imgSrc="./images/acme-cname-logo.svg"
                imgStyle={{}}
                url="https://www.acmecname.io"
            ></ResourceTile>
            </Row>
            <Row>
            <Col>
                <h2 className="section-header-text">Reseller Partner Program</h2>
            </Col>
            </Row>
            <Row>
            <ResourceTile
                title="Reseller Demo Site"
                imgSrc="./images/rpp.png"
                imgStyle={{
                width: '40%',
                }}
                url="https://rpp.pendoexperience.io"
                docUrl="https://pendo-io.atlassian.net/wiki/spaces/SE/pages/3236922110/Reseller+Partner+Program+Demo+Environment"
            ></ResourceTile>
            </Row>
        </Container>
    );
}